.header {
  background-color: rgb(40, 40, 40);
  color: rgb(250, 250, 250);
  display: block;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: medium;
  padding: 0.5rem 1rem 0.5rem;
}

.link {
  color: rgb(200, 200, 200);
  display: block;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: small;
  padding: 0.5rem 1rem 0.5rem;
  text-decoration: none;
}

.link:hover {
  background-color: rgb(75, 75, 75);
  cursor: pointer;
  color: white;
  transition: 0.1s;
}
