.LoadingContainer {
  display: relative;
  height: 100vh;
  text-align: center;
  width: 100vh;
}

.Loading {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #4a746e;
  border-radius: 50%;
  vertical-align: middle;
  position: absolute;
  top: 40%;
  left: 52.5%;
  width: 100px;
  height: 100px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
