.SideNavbar {
  box-shadow: 0 0 10px;
  position: sticky;
  top: 0;
  height: 100vh;
  width: 220px;
  background-color: #1b1c1c;
  overflow-y: scroll;
  -ms-overflow-style: none;
}

.SideNavbar::-webkit-scrollbar {
  display: none;
}

.SideNavbarHeader {
  color: rgb(250, 250, 250);
  display: block;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: x-large;
  padding: 3rem 0 3rem;
  text-align: center;
}

.SideNavbarFooter {
  bottom: 0;
  color: rgb(200, 200, 200);
  display: block;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: small;
  left: 0;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem 0.5rem;
  position: absolute;
  right: 0;
  text-decoration: none;
}

.SideNavbarFooter:hover {
  background-color: rgb(75, 75, 75);
  cursor: pointer;
  color: white;
  transition: 0.1s;
}
