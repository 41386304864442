.PageContainer {
  background-color: rgb(245, 240, 225);
  display: flex;
  height: fit-content;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
}

.Pages {
  background-color: rgb(250, 250, 250);
  box-shadow: 0 0 20px rgb(220, 220, 220);
  height: fit-content;
  margin-left: 40px;
  margin-right: 40px;
  overflow-y: scroll;
  padding: 20px 60px 80px 60px;
  width: 80%;
}

.Page {
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: large;
  height: fit-content;
  line-height: 1.7;
}
